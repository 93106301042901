@font-face {
  font-family: 'Biryani';
  src: local('Biryani-Regular'),
    url('/fonts/BiryaniLatin-Regular.woff') format('woff'),
    url('/fonts/BiryaniLatin-Regular.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Biryani';
  src: local('Biryani-Bold'),
    url('/fonts/BiryaniLatin-Bold.woff') format('woff'),
    url('/fonts/BiryaniLatin-Bold.woff2') format('woff2');
  font-weight: 700;
  font-style: normal;
  font-stretch: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Biryani';
  src: local('Biryani-ExtraBold'),
    url('/fonts/BiryaniLatin-ExtraBold.woff') format('woff'),
    url('/fonts/BiryaniLatin-ExtraBold.woff2') format('woff2');
  font-weight: 800;
  font-style: normal;
  font-stretch: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Zilla Slab';
  src: url('https://fonts.googleapis.com/css?family=Zilla+Slab&display=swap');
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Zilla Slab';
  src: url('https://fonts.googleapis.com/css?family=Zilla+Slab:400i&display=swap');
  font-weight: normal;
  font-style: italic;
  font-stretch: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Inter';
  src: url('/fonts/Inter-Regular.woff') format('woff'),
  url('/fonts/Inter-Regular.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Inter';
  src: url('/fonts/Inter-Medium.woff') format('woff'),
  url('/fonts/Inter-Medium.woff2') format('woff2');
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Inter';
  src: url('/fonts/Inter-SemiBold.woff') format('woff'),
  url('/fonts/Inter-SemiBold.woff2') format('woff2');
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Inter';
  src: url('/fonts/Inter-Bold.woff') format('woff'),
  url('/fonts/Inter-Bold.woff2') format('woff2');
  font-weight: 700;
  font-style: normal;
  font-stretch: normal;
  font-display: swap;
}

@font-face {
  font-family: 'PT Mono';
  src: url('/fonts/PT-Mono-Bold.woff') format('woff'),
  url('/fonts/PT-Mono-Bold.woff2') format('woff2');
  font-weight: 700;
  font-style: normal;
  font-stretch: normal;
  font-display: swap;
}

html,
body {
  padding: 0;
  margin: 0;
  font-family: 'Inter', 'Biryani', 'Zilla Slab', 'Arial', 'sans-serif';
  line-height: 1.6;
  font-size: 18px;
}

* {
  box-sizing: border-box;
}
